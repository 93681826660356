import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ForgotPass.scss';

export const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/reset-password`, {
        token,
        newPassword,
      });
      setMessage(response.data.message);
      setError('');
      setTimeout(() => navigate('/'), 2000);
    } catch (err) {
      setMessage('');
      setError(err.response?.data?.message || 'Error resetting password');
    }
  };

  return (
    <div className="container">
      <h2>Set a new password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Set a new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        {message && <p style={{ color: 'green' }}>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit">Save password</button>
      </form>
    </div>
  );
};