import React from 'react';
import "./Privacy.scss"
import { useTranslation } from 'react-i18next';

export const Privacy = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='privacy_policy'>
      <div className='privacy'>
        <div className='privacy_title' >
          <p>{t('privacy.privacy')}</p>
        </div>
        <div className='privacy_main'>
          <div className='privacy_main_left'>
            <div>
              <span>{t('privacy.date')}</span>
            </div>
            <div>
              <p>{t('privacy.info')}</p>
            </div>
            <div>
              <span>{t('privacy.title_1')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_1_2')}</p>
            </div>
            <div>
              <span>{t('privacy.title_2')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_2_2')}</p>
              <p>{t('privacy.desc_2_3')}.</p>
              <p>{t('privacy.desc_2_4')}</p>
            </div>
            <div>
              <span>{t('privacy.title_3')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_3')}</p>
            </div>
          </div>

          <div className='privacy_main_right'>
            <div>
              <span>{t('privacy.title_4')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_4')}</p>
            </div>
            <div>
              <span>{t('privacy.title_5')}</span>
            </div>

            <div>
              <p>{t('privacy.desc_5')}</p>
            </div>
            <div>
              <span>{t('privacy.title_6')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_6')}</p>
            </div>
            <div>
              <span>{t('privacy.title_7')}</span>
            </div>
            <div>
              <p>{t('privacy.desc_7')}</p>
            </div>
            <div className='privacy_main_right_info' >
              <span>{t('privacy.Phone')} +374 94 80 59 80</span>
              <span>{t('privacy.email')} <a href="">idealgift.am@gmail.com</a> </span>
              <span>{t('privacy.address')}</span>
              <span>{t('privacy.instagram')} <a href="https://www.instagram.com/idealgift.am?igsh=cnZ3dXU0MmMxdndh" target='blank'>@idealgift.am</a> </span>
              <span>{t('privacy.facebook')} <a href="https://www.facebook.com/share/1FDZ7bcHhD/?mibextid=wwXIfr" target='blank'>IdealGift</a> </span>
              <span>{t('privacy.linkedIn')} <a href="http://linkedin.com/in/ideal-gift-162179305" target='blank'>IdealGift</a> </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}