import { useRoutes } from 'react-router-dom'
import { Home } from '../../pages/home/Home'
import { Login } from '../../pages/login/Login'
import { Profile } from '../../pages/profile/Profile'
import { About } from '../../pages/about/About'
import { Contact } from '../../pages/contact/Contact'
import { Faq } from '../../pages/faq/Faq'
import { Privacy } from '../../pages/privacy/Privacy'
import { Basket } from '../../pages/basket/Basket'
import { Catalog } from '../../pages/catalog/Catalog'
import { Product } from '../../pages/product/Product'
import { Order } from '../../pages/order/Order'
import { Corporate } from '../../pages/corporate/Corporate'
import { SeeProduct } from '../../pages/product/see_product/SeeProduct'
import { Admin } from '../../pages/admin/Admin'
import { Payment } from '../../pages/payment/Payment'
import {ForgotPass} from '../../pages/forgot_pass/ForgotPass'
import {ResetPassword} from '../../pages/forgot_pass/ResetPassword'

export const MyRouter = () => {
  const router = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/profile',
      element: <Profile />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/contact',
      element: <Contact />
    },
    {
      path: '/faq',
      element: <Faq />
    },
    {
      path: '/privacy',
      element: <Privacy />
    },
    {
      path:"/basket",
      element:<Basket/>
    },
    {
      path:"/catalog",
      element:<Catalog/>
    },
    {
      path:"/product/:name",
      element:<Product/>
    },
    {
      path:"/order",
      element:<Order/>
    },
    {
      path:"/corporative-section",
      element:<Corporate/>
    },
    {
      path:"/product/:name/:id",
      element:<SeeProduct/>
    },
    {
      path:"/admin",
      element:<Admin/>
    },
    {
      path:"/payment-status/:id",
      element:<Payment/>
    },
    {
      path:"/forgot-password",
      element:<ForgotPass/>
    },
    {
      path:"/reset-password/:token",
      element:<ResetPassword/>
    },
    {
      path: '*', 
      element: <Home />
    },
  ])
  return router
}