import React from "react";
import "./PriceModal.scss"
import cityData from "../../../assets/city.json";
import { useDispatch } from "react-redux";
import { deliveryPriceListClose } from "../../../features/products/productsSlice";


export const PriceModal = () => {
  const dispatch = useDispatch()

  const modalClose = () => {
    dispatch(deliveryPriceListClose())
  }

  return (
    <div className="price_modal_container">
      <div className="price_modal_container_title">
        <p>Price List Delivery</p>
      </div>
      <div className="price_modal_container_city">
        <div className="price_modal_container_city_box">
          {
            cityData.map(elm => {
              if (elm.id < 32) {
                return (
                  <div className="price_modal_container_city_box_item">
                    <p>{elm.city}</p>
                    <p>{elm.price}֏</p>
                  </div>
                )
              }
            })
          }
        </div>
        <div>
          <img src="/img/map.png" alt="" />
        </div>
        <div className="price_modal_container_city_box">
          {
            cityData.map(elm => {
              if (elm.id >= 32) {
                return (
                  <div className="price_modal_container_city_box_item">
                    <p>{elm.city}</p>
                    <p>{elm.price}֏</p>
                  </div>
                )
              }
            })
          }
        </div>
      </div>
      <img width={14} height={14} onClick={() => modalClose()} className="price_modal_container_close" src="./svg/close.svg" alt="" />
    </div>
  )
}