import React from 'react';
import "./Contact.scss"
import { useTranslation } from 'react-i18next';

export const Contact = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='contact_us'>
      <div className='contact'>
        <div className='contact_title' >
          <p>{t('contact.contact')}</p>
        </div>
        <div className='contact_main'>
          <div className='contact_main_left'>
            <div>
              <span>{t('contact.info')}</span>
            </div>
            <div>
              <span>{t('contact.title_1')}</span>
              <p></p>
              <span>{t('contact.title_2')}</span>
              <p></p>

              <span>{t('contact.title_4')}</span>
              <p></p>

              <span>{t('contact.title_5')}</span>

            </div>
            <div className='contact_info'>
              <p>{t('privacy.privacy')}  <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}