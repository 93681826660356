import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addProductTunk = createAsyncThunk(
  'brand/addProduct',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/product`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);

export const getProductTunk = createAsyncThunk(
  'brand/getProduct',
  async (data) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/get/product`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const getAllProductTunk = createAsyncThunk(
  'brand/getAllProduct',
  async (data) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/get/all/product`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);


export const deleteProductTunk = createAsyncThunk(
  'brand/deleteProduct',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/delete/product`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const editProductTunk = createAsyncThunk(
  'brand/editProduct',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/edit/product`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const activetedProductTunk = createAsyncThunk(
  'brand/activetedProduct',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/activeted/product`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);