import React, { useEffect, useState } from "react"
import "./Basket.scss"
import { BasketTable } from "./basketTable/BasketTable"
import { useDispatch, useSelector } from "react-redux";
import { deleteAllBasketItems } from "../../features/products/productsSlice";
import { useNavigate } from "react-router-dom";

export const Basket = () => {
  const { basket } = useSelector((state) => state.products);
  const navigate = useNavigate()
  const [totalPrice, setTotalPrice] = useState(basket !== null ? basket.reduce((acc, item) => acc + (item.count * item.price), 0) : 0)
  const dispatch = useDispatch()
  
  useEffect(() => {
    setTotalPrice(basket !== null ? basket.reduce((acc, item) => acc + (item.count * item.price), 0) : 0)
  }, [basket])

  const deleteAllProducts = () => {
    dispatch(deleteAllBasketItems())
  }

  const placeAnOrder = () => {
    if (totalPrice > 0) {
      navigate("/order")
    }
  }

  return (
    <div className="basket">
      <div className='basket_container'>
        <div className='basket_container_title'>
          <p>BASKET</p>
        </div>
        <div className='basket_container_info'>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>№ ORDER</th>
                  <th>PRODUCT</th>
                  <th>QUANTITY</th>
                  <th>PRICE</th>
                  <th>CODE</th>
                  <th>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteAllProducts()}
                      width={14}
                      height={14}
                      src="/svg/close.svg"
                      alt=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {basket?.map((item, index) => {
                  return <BasketTable key={index} item={item} index={index} />;
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>USBTOTAL</th>
                  <th></th>
                  <th></th>
                  <th>{totalPrice}֏ </th>
                  <th colSpan="2">
                    <button onClick={() => placeAnOrder()}>PLACE AN ORDER</button>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

  )
}