import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getBrandTunk } from "../../../features/brand/brandAPI";
import { getCategoryTunk } from "../../../features/category/categoryAPI";
import { addProductTunk, deleteProductTunk, editProductTunk, getAllProductTunk, getProductTunk } from "../../../features/products/productsAPI";
import "./Products.scss"

export const Products = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [files, setFiles] = useState([]);
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const form1 = useForm();
  const dispatch = useDispatch()
  const { productList } = useSelector((state) => state.products);
  const { brandList } = useSelector((state) => state.brand);
  const { categoryList } = useSelector((state) => state.category);

  const addProduct = (data) => {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => formData.append("file", file));
    }
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("CategoryId", data.CategoryId);
    formData.append("BrendId", data.BrendId);

    dispatch(addProductTunk(formData));
    reset();
    setFiles([])
    setTimeout(() => {
      dispatch(getAllProductTunk())
    }, 500);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
    setValue("files", updatedFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setValue("files", updatedFiles);
  };

  const deleteProduct = async (id) => {
   await dispatch(deleteProductTunk({ id }))
    dispatch(getAllProductTunk())
  }

  const openModal = (product) => {
    setModalOpen(true);
    setCurrentProduct(product);
    form1.setValue("name", product.name);
    form1.setValue("description", product.description);
    form1.setValue("price", product.price);
    form1.setValue("BrendId", product.BrendId);
    form1.setValue("CategoryId", product.CategoryId);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentProduct(null);
  };

  const submitEdit = (data) => {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => formData.append("file", file));
    }
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("CategoryId", data.CategoryId);
    formData.append("BrendId", data.BrendId);

    reset();
    setFiles([])
    setTimeout(() => {
      dispatch(getAllProductTunk())
    }, 500);

    formData.append("id", currentProduct.id);
    dispatch(editProductTunk(formData))
    dispatch(getProductTunk())
    closeModal();

    dispatch(editProductTunk({ name: data.name, id: currentProduct.id }))
    dispatch(getProductTunk())
    closeModal();

  };

  const filterCategory = (id) => {
    const data = categoryList.filter(elm => elm.id === id)
    return data[0]?.name
  }

  const filterBrand = (id) => {
    const data = brandList.filter(elm => elm.id === id)
    return data[0]?.name
  }

  useEffect(() => {
    dispatch(getAllProductTunk())
    dispatch(getBrandTunk())
    dispatch(getCategoryTunk())
  }, [])

  return (
    <div className="admin_product">
      <div className="admin_product_control">
        <form onSubmit={handleSubmit(addProduct)}>
          <input {...register("name", { required: true })} type="text" placeholder="Product Name" />
          <div className="file-upload">
            <input
              type="file"
              onChange={handleFileChange}
              className="file-input"
            />
            <div className="file-preview">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className="file-image"
                  />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
          <textarea {...register("description", { required: true })} placeholder="Description"></textarea>
          <input {...register("price", { required: true })} type="number" placeholder="Price" />
          <select {...register("CategoryId", { required: true })}>
            <option disabled selected>Category</option>
            {categoryList?.map((elm) => {
              return (
                <option key={elm.id} value={elm?.id}>{elm?.name}</option>
              )
            })}
          </select>
          <select {...register("BrendId", { required: true })}>
            <option disabled selected>Brand</option>
            {brandList?.map((elm) => {
              return (
                <option key={elm.id} value={elm?.id}>{elm?.name}</option>
              )
            })}
          </select>
          <button type="submit">Add</button>
        </form>
      </div>

      <div className="admin_product_table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Price</th>
              <th>Description</th>
              <th>Brand</th>
              <th>Category</th>
              <th>Id</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productList.length > 0 && productList?.map((elm) => {
              return (
                <tr key={elm.id}>
                  <td>
                    {
                      elm?.ProductImgs && elm?.ProductImgs?.length &&
                      <img width={90} height={90} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm?.ProductImgs[0]?.url}`} alt="" />
                    }
                  </td>
                  <td>
                    {elm.name}
                  </td>
                  <td>
                    {elm.price}֏
                  </td>
                  <td style={{ maxWidth: "200px", textWrap: "wrap", overflow: "hidden" }}>
                    {elm.description}
                  </td>
                  <td>
                    {filterBrand(elm.BrendId)}
                  </td>
                  <td>
                    {filterCategory(elm.CategoryId)}
                  </td>
                  <td>
                    {elm.id}
                  </td>
                  <td className="td">
                    <img width={14} height={14} src="./svg/close.svg" onClick={() => deleteProduct(elm.id)} alt="" />
                    <img width={14} height={14} src="./svg/edit.svg" onClick={() => openModal(elm)} alt="" />
                  </td>
                </tr>
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal_content">
            <h3>Edit Product</h3>
            <form onSubmit={form1.handleSubmit(submitEdit)}>
              <input {...form1.register("name", { required: true })} type="text" placeholder="Product Name" />
              <div className="file-upload">
                <input
                  type="file"
                  placeholder="add"
                  onChange={handleFileChange}
                  className="file-input"
                />
                <div className="file-preview">
                  {files.map((file, index) => (
                    <div className="file-item" key={index}>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Preview ${index}`}
                        className="file-image"
                      />
                      <button
                        type="button"
                        className="remove-button"
                        onClick={() => handleRemoveFile(index)}
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <textarea {...form1.register("description", { required: true })} placeholder="Description"></textarea>
              <input {...form1.register("price", { required: true })} type="number" placeholder="Price" />
              <select {...form1.register("CategoryId", { required: true })}>
                <option disabled selected>Category</option>
                {categoryList?.map((elm) => {
                  return (
                    <option key={elm.id} value={elm?.id}>{elm?.name}</option>
                  )
                })}
              </select>
              <select {...form1.register("BrendId", { required: true })}>
                <option disabled selected>Brand</option>
                {brandList?.map((elm) => {
                  return (
                    <option key={elm.id} value={elm?.id}>{elm?.name}</option>
                  )
                })}
              </select>
              <button type="submit">Save</button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}