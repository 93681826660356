import React, { useEffect, useState } from "react";
import "./SeeProduct.scss"
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBasket } from "../../../features/products/productsSlice";
import { loginModalOpen } from "../../../features/login/loginSlice";
import axios from 'axios';
import useWindowWidth from "../../components/window-size";
import { useTranslation } from "react-i18next";

export const SeeProduct = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [itemCount, setItemCount] = useState(1)
  const { accessToken } = useSelector((state) => state.login);
  const [isChart, setIsChart] = useState(false);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const navigate = useNavigate()
  const width = useWindowWidth();
  const { t, i18n } = useTranslation();

  const plusCount = () => {
    setItemCount(itemCount + 1)

  }
  const minusCount = () => {
    if (itemCount > 1) {
      setItemCount(itemCount - 1)
    }
  }

  const getReqCategoryPrdouct = async (id) => {
    const {
      data
    } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/product/req/category`, {
      id,
    })
    if (data) {
      setProducts(data)
    }
  }

  const addToCart = (item) => {
    if (accessToken) {
      setIsChart(true);
      const local = JSON.parse(localStorage.getItem("basket"))
      const data = {
        ...item,
        count: itemCount
      }
      const localData = local?.filter(elm => elm.id === data.id)

      if (local) {
        if (!localData.length) {
          dispatch(addBasket(data))
        }
      } else {
        dispatch(addBasket(data))
      }

    } else {
      dispatch(loginModalOpen())
    }
  }

  const getProductData = async (id) => {

    const {
      data
    } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/product`, {
      id,
    })
    if (data) {
      setProduct(data)
      setSelectedImage(data?.ProductImgs[0]?.url)
    }
  }

  useEffect(() => {
    if (id) {
      getProductData(id)
      getReqCategoryPrdouct(id)
    }
    const local = localStorage.basket
    if (local) {
      JSON.parse(local).map(item => {
        if (item.id === +id) {
          setIsChart(true)
        }
      })
    }
  }, [id])

  const [selectedImage, setSelectedImage] = useState();
  return (
    <div className="product_item">
      <div className="product_item_container">
        <div className="product_item_container_title">
          <p>SHOP</p>
        </div>
        <div className="product_item_container_box">
          <div className="product_item_container_box_img">
            <div className="product-page">
              <div className="product-page__main-image">
                <img src={`${process.env.REACT_APP_BASE_IMG_URL}${selectedImage}`} alt="Selected" />
              </div>

              <div className="product-page__thumbnails">
                {
                  product && product?.ProductImgs.map((image) => (
                    <img
                      key={image.id}
                      src={`${process.env.REACT_APP_BASE_IMG_URL}${image.url}`}
                      className={`product-page__thumbnail ${selectedImage === image.url ? "active" : ""
                        }`}
                      onClick={() => setSelectedImage(image.url)}
                    />
                  ))}
              </div>

              <div className="product-page__info">
                <div style={{display: "flex", flexDirection:"column", alignItems:"start", gap:"10px"}}>
                  <div className="product-page__info_title">
                    <span>{product?.name}</span>
                  </div>
                  <p>{product?.price}֏</p>
                </div>
                <div className="info-down" >
                  <div className="product-page__info_quantity">
                    <button onClick={() => minusCount()}>-</button>
                    <span>{itemCount}</span>
                    <button onClick={() => plusCount()}>+</button>
                  </div>
                  <div onClick={() => addToCart(product)} className={isChart ? "product-page__add-chart__activ" : "product-page__add-chart"}>
                    {
                      width <= 450 ? (
                        <div >
                          <p >{t('chart').toUpperCase()}</p>
                        </div>
                      ) : (
                        <img src={isChart ? "/svg/cart_activ.svg" : "/svg/cart.svg"} className="product-page__info_add-to-cart" />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product_item_container_box_info">
            <p>
              {
                product?.description
              }
            </p>
          </div>
        </div>
        <div className="product_item_container_recommend">
          {
            products && products?.map(elm => {
              return (
                <>{
                  elm.ProductImgs && elm.ProductImgs.length ?
                    <div className="product_item_container_recommend__box" >
                      <img style={{ cursor: 'pointer' }} onClick={() => navigate(`/product/${elm.name}/${elm.id}`)} width={150} height={180} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm.ProductImgs[0].url}`} />
                      <div className="product_item_container_recommend__box__title"  >
                        <span>{elm?.name}</span>
                      </div>
                    </div>
                    : <></>
                }
                </>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}