import React, { useState } from 'react';
import './Burger.scss';
import { useSelector } from 'react-redux';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { basketCount } = useSelector((state) => state.products);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="burger-menu">
      <button className="burger-icon" onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>

      <nav className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/about">about</a></li>
          <li><a href="/faq">faq</a></li>
          <li><a href="/contact">contact us</a></li>
          <li><a href="/catalog">catalog</a></li>
          {/* <li><a href="#contact">search</a></li> */}
          <li><a href="/basket">cart({basketCount})</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default BurgerMenu;
