import React, { useState, useEffect } from 'react';
import "./FavoriteBrands.scss";
import axios from 'axios';
import {
  useNavigate,
  useParams
} from "react-router-dom";

import {
  useDispatch,
  useSelector
} from "react-redux"

export const FavoriteBrands = () => {
  const { userInfo, accessToken } = useSelector((state) => state.login)
  const [product, setProduct] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getProducts = async (id) => {
    const {
      data
    } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/favorit/product`, {
      UserId: id,
    })
    if (data.length) {
      setProduct(data)
    }
  }

  const deleteFavorite = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/favorit/product`, {
        userId: userInfo.id,
        productId: id
      });
      setProduct((prevProducts) => prevProducts.filter((product) => product.id !== id));
    } catch (error) {
      console.error("Error deleting favorite product:", error);
    }
  };

  useEffect(() => {
    if (userInfo?.id) {
      getProducts(userInfo.id)
    }
  }, [userInfo])

  return (
    <div className='brands'>
      {
        product && product?.map((elm, index) => {
          return (
            <div key={index} className='brands_item'>
              <div className='brands_item_box'>
                <img
                  onClick={() => navigate(`/product/${elm.name}/${elm.id}`)}
                  width={210}
                  height={210}
                  src={`${process.env.REACT_APP_BASE_IMG_URL}${elm?.ProductImgs[0]?.url}`} alt="" />
                <div>
                  <img onClick={() => deleteFavorite(elm.id)} src="./svg/heart_red_active.svg" alt="" />
                </div>

              </div>
              <p>{elm?.name}</p>
              <p>{elm?.price} </p>
            </div>
          )
        })
      }
    </div>
  )
}