import React, { useEffect, useState } from "react"
import "./DeliveryAddress.scss"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import cityData from "../../../assets/city.json";
import { addDeliveryPrice } from "../../../features/products/productsSlice";
import { useTranslation } from "react-i18next";

export const DeliveryAddress = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { userInfo } = useSelector((state) => state.login)
  const formData = watch();
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();


  useEffect(() => {
    console.log('111');
    
    if (userInfo) {
      setValue("city", userInfo.city || "");
      setValue("address", userInfo.address || "");
      setValue("apartment", userInfo.apartment || "");
      setValue("floor", userInfo.floor || "");
      setValue("intercom", userInfo.intercom || "");
    }
  }, [userInfo, setValue]);

  useEffect(() => {
    console.log('formData', formData)
   
      localStorage.setItem('delivery_address', JSON.stringify(formData))
      const deliveryPrice = cityData.find((city) => city.city === formData?.city)
      if (deliveryPrice) {
        dispatch(addDeliveryPrice(deliveryPrice?.price))
      }
  }, [formData])

  return (
    <div className="delivery_address">
      <form >
        <div className="addres_input">
          <select
            id="city-select"
            value={formData?.city}
            {...register("city")}
          >
            <option value="" disabled >
              {t('order.select_city')}
            </option>
            {cityData.map((city) => (
              <option key={city.id} value={city.city}>
                {city.city} - {city.price} ֏
              </option>
            ))}
          </select>
          {formData.city === '' && <small className="error">{t('reg.error')}</small>}
        </div>
        <div className="addres_input">
          <input {...register("address")} placeholder={t('account.adress')} defaultValue={userInfo?.addres} />
          {formData.address === '' && <small className="error">{t('reg.error')}</small>}
        </div>
        <div>
          <input {...register("apartment")} placeholder={t('account.apartment')} defaultValue={userInfo?.apartment} />
          <input {...register("floor")} placeholder={t('account.floor')} defaultValue={userInfo?.floor} />
          <input {...register("intercom")} placeholder={t('account.intercom')} defaultValue={userInfo?.intercom} />
        </div>
      </form>
    </div>
  )
}