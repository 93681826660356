import React, { useEffect, useState } from 'react';
import styles from "./OrderHistory.module.scss"
import axios from 'axios';
import {
  useDispatch,
  useSelector
} from "react-redux"
export const OrderHistory = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  const {
    userInfo
  } = useSelector((state) => state.login)

  const toggleOrder = (index) => {
    setActiveOrder(activeOrder === index ? null : index);
  };
  const [orders, setOrders] = useState([])
  const getOrders = async () => {
    if (userInfo.role === "admin") {
      const {
        data
      } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/all/orders`)
      setOrders(data)
    }
    else {
      const {
        data
      } = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/orders`, {
        id: userInfo.id,
      })
      setOrders(data)
    }

  }

  const  formatDateTime = (isoDateString) => {
    const date = new Date(isoDateString);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}-${month}-${year} ' ${hours}:${minutes}`;
  }

  useEffect(() => {
    getOrders()
  }, [])

  console.log('orders', orders);


  return (
    <div className={styles.accordion}>
      <div className={styles.headerRow}>
        <span>No Order</span>
        <span>Order Date</span>
        <span>Status</span>
        <span>Paid</span>
      </div>
      {orders?.map((order, index) => (
        <div key={index} className={`${styles.order} ${activeOrder === index ? styles.active : ""}`}>
          <div
            className={`${styles.row} ${activeOrder === index ? styles.active : ""}`}
            onClick={() => toggleOrder(index)}
          >
            <span>{order.id}</span>
            <span>{`Purchased ${formatDateTime(order.createdAt)}`}</span>
            <span>COMPLETED</span>
            <span>{order.totalPrice}֏</span>
            <div className={styles.toggleIcon}>
              {activeOrder === index ? "▲" : "▼"}
            </div>
          </div>
          {activeOrder === index && (
            <>
              {order?.OrderProducts.map((elm,index) => {
                return (
                  <div key={index} className={styles.details}>
                    <div className={styles.product}>
                      <img width={100} height={100} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm?.Product?.ProductImgs[0]?.url}`} alt="" />
                      <div className={styles.item}>
                        <p>
                          {elm?.Product?.name}
                        </p>
                        <div></div>
                        <p>{elm?.Product?.price}֏</p>
                        <div></div>
                        <p>Quantity: {elm?.count}</p>
                      </div>
                    </div>
                    <div className={styles.info}>
                      <div>
                        <span>CONTACT DETAILS:</span>
                        <p>{`${order?.city}, ${order?.addres}, ${order?.apartment}, ${order?.floor}, ${order?.intercum} `}</p>
                      </div>
                    </div>
                    <div className={styles.delivery}>
                      <span>ORDER DETAILS:</span>
                      <p>{order?.delMetod}</p>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      ))}
    </div>
  )
}