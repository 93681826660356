import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPass.scss';

export const ForgotPass = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [theme, setTheme] = useState('dark');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/forgot-password`, { email });
      setMessage(response.data.message);
      setError('');
    } catch (err) {
      setMessage('');
      setError(err.response?.data?.message || 'Error sending request');
    }
  };

  return (
    <div className="container">
      <h2>Password reset</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {message && <small  style={{ color: 'green', marginBottom:"5px" }}>{message}</small>}
        {error && <small style={{ color: 'red' }}>{error}</small>}
        <button type="submit">Send</button>
      </form>
    </div>
  );
};