import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "./Product.scss"
import { useDispatch, useSelector } from "react-redux"
import { addBasket } from "../../features/products/productsSlice"
import { ProdItems } from "./ProdItems"
import axios from 'axios'

export const Product = () => {
  const { name } = useParams()
  const { basket } = useSelector((state) => state.products);
  const [product, setProduct] = useState([])
  const { userInfo } = useSelector((state) => state.login)

  const toUpperCaseText = (text) => {
    return text.toUpperCase(text);
  }

  const getProductsByCategoryName = async (name, id) => {
    const
      { data }
        = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/get/product/category`, {
          name,
          userId: id,
        })
    if (data.length) {
      setProduct(data)
    }
  }

  useEffect(() => {
    if (name) {
      getProductsByCategoryName(name, userInfo?.id ? userInfo?.id : '')
    }
  }, [name, userInfo])

  return (
    <div className="product">
      <div className='product_container'>
        <div className='product_container_title'>
          <p>{toUpperCaseText(name)}</p>
        </div>
        <div className='product_container_list'>
          {
            product && product.map((elm, index) => {
              return (
                <ProdItems key={index} elm={elm} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}