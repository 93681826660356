import React, { useEffect, useState } from "react";
import './DetailsAndPayment.scss'
import { useDispatch } from "react-redux";
import cityData from "../../../assets/city.json";
import { addDeliveryPrice, deliveryPriceListOpen } from "../../../features/products/productsSlice";
import { useTranslation } from "react-i18next";
export const DetailsAndPayment = () => {

  const [deliveryMethod, setDeliveryMethod] = useState("courier");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    localStorage.setItem('delMetod', event.target.value)
    setDeliveryMethod(event.target.value);
    if (event.target.value === 'pickup') {
      dispatch(addDeliveryPrice(0))
    }
    else {
      const formData = JSON.parse(localStorage.getItem('delivery_address'))
      const deliveryPrice = cityData.find((city) => city.city === formData?.city)
      if (deliveryPrice) {
        dispatch(addDeliveryPrice(deliveryPrice?.price))
      }
    }
  };
  const handlePaymentChange = (event) => {
    localStorage.setItem('peyMet', event.target.value)
    setPaymentMethod(event.target.value);
  };

  const openPriceModal = () => {
    dispatch(deliveryPriceListOpen())
  }

  useEffect(() => {
    localStorage.setItem('delMetod', "courier")
    localStorage.setItem('peyMet', "card")
    const formData = JSON.parse(localStorage.getItem('delivery_address'))
    const deliveryPrice = cityData.find((city) => city.city === formData?.city)
    if (deliveryPrice) {
      dispatch(addDeliveryPrice(deliveryPrice?.price))
    }
  }, [])

  return (
    <div className="details_and_payment">
      <div className="details_and_payment_config">
        <div className="details_and_payment_config_delivery">
          <p>{t('order.delivery')}</p>
          <div className="details_and_payment_config_delivery_box">
            <input
              type="radio"
              value="courier"
              id="courier"
              checked={deliveryMethod === "courier"}
              onChange={handleChange}
            />
            <label htmlFor="courier">
              {t('order.delivery_metod_1')}
            </label>
          </div>
          <div className="details_and_payment_config_delivery_box">

            <input
              type="radio"
              value="pickup"
              id="pickup"
              checked={deliveryMethod === "pickup"}
              onChange={handleChange}
            />
            <label htmlFor="pickup">
              {t('order.delivery_metod_2')}

            </label>
          </div>
        </div>
        <div className="details_and_payment_config_payment">
          <p> {t('order.payment_method')}</p>
          <div className="details_and_payment_config_payment_box">
            <input
              type="radio"
              value="card"
              id="card"
              checked={paymentMethod === "card"}
              onChange={handlePaymentChange}
            />
            <label htmlFor="card">

            {t('order.payment_method_1')}
            </label>
          </div>
          <div className="details_and_payment_config_payment_box">

            <input
              type="radio"
              value="cash"
              id="cash"
              checked={paymentMethod === "cash"}
              onChange={handlePaymentChange}
            />
            <label htmlFor="cash">
            {t('order.payment_method_2')}

            </label>
          </div>
        </div>
      </div>
      <div className="details_and_payment_note">
        <p>{t('order.note')}</p>
        <textarea name="" id="" placeholder={t('order.message')} onChange={(e) => localStorage.setItem('note', e.target.value)}></textarea>
      </div>
      <div className="details_and_payment_info">
        <p>{t('order.info')}</p>
        <a onClick={() => openPriceModal()} >{t('order.list')}</a>
      </div>

    </div>
  )
}