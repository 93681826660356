import React, { useEffect, useState } from "react";
import "./Product.scss"
import { useDispatch, useSelector } from "react-redux";
import { addBasket } from "../../features/products/productsSlice";
import { loginModalOpen } from "../../features/login/loginSlice";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

export const ProdItems = ({ key, elm }) => {
  const dispatch = useDispatch()
  const [isFavorite, setIsFavorite] = useState(false);
  const [isChart, setIsChart] = useState(false);
  const { accessToken, userInfo } = useSelector((state) => state.login);
  const navigate = useNavigate()
  const { pathname, search } = window.location;

  const addFavorite = async (id) => {
    if (accessToken) {
      setIsFavorite((prev) => !prev);
      await axios.post(`${process.env.REACT_APP_BASE_URL}/product/add/favorit/product`, {
        userId: userInfo.id,
        productId: id
      })
    } else {
      dispatch(loginModalOpen())
    }
  }

  const addToCart = (item) => {
    if (accessToken) {
      setIsChart(true);

      const local = JSON.parse(localStorage.getItem("basket"))
      const data = {
        ...item,
        count: 1
      }
      const localData = local?.filter(elm => elm.id === data.id)

      if (local) {
        if (!localData.length) {
          dispatch(addBasket(data))
        }
      } else {
        dispatch(addBasket(data))
      }

    } else {
      dispatch(loginModalOpen())
    }
  }

  useEffect(() => {
    const local = localStorage.basket
    if (local) {
      JSON.parse(local).map(item => {
        if (item.id === elm.id) {
          setIsChart(true)
        }
      })
    }
    setIsFavorite(elm.isFavorite)
  }, [])

  return (
    <div key={ key }
      className="product_container_list_item" >
      {
        elm?.ProductImgs && elm?.ProductImgs.length &&
        <img onClick={() => navigate(`${pathname}/${elm.id}`)} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm?.ProductImgs[0].url}`} alt="" />
      }
      <div className="product_container_list_item_info">
        <div className="product_container_list_item_info_title">
          <p onClick={() => navigate(`${pathname}/${elm.id}`)}>{elm?.name}</p>
          <img onClick={() => addFavorite(elm.id)} src={isFavorite ? "/svg/heart_red_active.svg" : "/svg/heart_red.svg"} alt="" />
        </div>
        <div className="product_container_list_item_info_desc">
          <p> {elm?.price}֏ </p>
          <img onClick={() => addToCart(elm)} src={isChart ? "/svg/cart_activ.svg" : "/svg/cart.svg"} alt="" />
        </div>
      </div>
    </div>
  )
}